import model from '../model';
import FamilyVariant from './familyVariant';
import ShareModule from './share';
const { deserializeConcatString, parseGwas, parsePhenoCounts } = ShareModule;


class FamilySnv extends FamilyVariant {
  isSv: boolean;
  ref: any;
  alt: any;
  dpText: any;
  gqText: any;
  qualText: any;
  cnnText: any;
  rohText: any;
  refReadsText: any;
  altReadsText: any;
  geneText: any;
  genotypes: any;
  phases: any;
  genotypesText: any;
  ensp: any;
  hgvsp: any;
  enst: any;
  hgvsc: any;
  omim: {
    omimNumber: number; phenoCode: any; gene: any; inheritanceCode: number; phenotypes: any; // remove nullified cancer entries
  };
  clinvar: { pmid: any; rcv: any; clinsig: any; phenolist: any; cdna: any; clinsigCode: any; clinsigCodeStrict: any; };
  gwas_matchtype: any;
  gwas: any;
  ncbi: any;
  gnomadConstraint: { pli: any; mis_z: any; exp_lof: any; obs_lof: any; exp_mis: any; obs_mis: any; exp_syn: any; obs_syn: any; syn_z: any; }[];
  maverick: { benign: any; dom: any; rec: any; inherit: any; };
  gerp: { neutral: any; rs: any; };
  spliceai: { acceptor_gain: any; acceptor_loss: any; donor_gain: any; donor_loss: any; acceptor_gain_dist: any; acceptor_loss_dist: any; donor_gain_dist: any; donor_loss_dist: any; max: any; };
  ccr: number;
  gnomad3: { gnomad_ac: any; gnomad_an: any; gnomad_af: any; gnomad_het: any; gnomad_hom: any; gnomad_ac_afr: any; gnomad_ac_amr: any; gnomad_ac_asj: any; gnomad_ac_eas: any; gnomad_ac_fin: any; gnomad_ac_nfe: any; gnomad_ac_oth: any; gnomad_an_afr: any; gnomad_an_amr: any; gnomad_an_asj: any; gnomad_an_eas: any; gnomad_an_fin: any; gnomad_an_nfe: any; gnomad_an_oth: any; };
  phenoCounts: any;
  genesisCounts: any;
  transcripts: any;
  worstTranscript: any;
  ontology: any;
  ontologyText: any;
  afRating: number;
  conservRating: number;
  funcRating: number;
  variantId: string;
  chrom: any;
  bp: any;
  familyAlleles: string;
  sampleGenotypes: any[];
  constructor(data, params) {
    const variantId = `${data.chromosome}_${data.position}_${data.reference}_${data.alternate}`
    super(data, params, variantId);
    // this.genes = data.vep_gene;
    this.isSv = false;
    this.ref = data.reference;
    this.alt = data.alternate;
    this.dpText = data.dp;
    this.gqText = data.gq;
    this.qualText = data.qual;
    this.cnnText = data.cnn;
    this.rohText = data.roh;
    this.refReadsText = data.ref_reads;
    this.altReadsText = data.alt_reads;
    this.geneText = data.vep_gene;
    this.genotypes = data.genotypes.split(",");
    this.phases = data.hasOwnProperty("phases") ? String(data.phases).split(",") : null;
    this.genotypesText = data.genotypes;
    if (data.hgvsp) [this.ensp, this.hgvsp] = data.hgvsp.split(':p.');
    if (data.cdna) [this.enst, this.hgvsc] = data.cdna.split(':');
    // EXAC is obsolete and retained only for compatibility with the data
    // version
    let [ncbi, gwas, gnomadConstraint, conservation, clinvar, exac, 
      nhlbi, splice, regulatory, evolution, functional, kgenomes, gnomad,
      maverick, gerp, spliceai, ccr, gnomad3, omim
    ] = data.clientdata.split('!@&');
    if (omim) {
      let [omimNumber, geneName, gene, phenotype, phenoCode, phenotypes,
        inheritanceCode
      ] = omim.split('@');
      let rx1 = /(.*), (\d+) \(\d\), (.*)/, rx2 = /(.*), (\d+) \(\d\)/;
      // Match cancer entries (eg "Lymphoma, B-cell non-Hodgkin, somatic (3)")
      const rx3 = /.*, somatic \(\d\)/;  
      this.omim = {
        omimNumber: Number(omimNumber), phenoCode, gene,
        inheritanceCode: Number(inheritanceCode),
        phenotypes: phenotypes.split(';').map(p => {
          p = p.trim();
          if (rx1.test(p)) {
            let [phenotype, omim_phenotype_mim, inheritance] = rx1.exec(p).slice(1);
            return {phenotype, omim_phenotype_mim, inheritance};
          } else if (rx2.test(p)) {
            let [phenotype, omim_phenotype_mim] = rx2.exec(p).slice(1);
            return {phenotype, omim_phenotype_mim};
          } else if (rx3.test(p)) return null;
        }).filter(p => p != null)  // remove nullified cancer entries
      };
    }
    if (clinvar) this.clinvar = parseClinvar(clinvar);
    // GWAS has an unfortunate format (that should really be fixed upstream) requiring
    // the match type to be parsed off the end before the rest can be parsed
    if (gwas) {
      let matchDelim = gwas.lastIndexOf('<>');
      this.gwas_matchtype = gwas.slice(matchDelim+2);
      this.gwas = parseGwas(gwas.slice(0,matchDelim));
    }
    if (ncbi) this.ncbi = parseNcbi(ncbi);
    if (gnomadConstraint) {
      let [pli, mis_z, obs_lof, exp_lof, obs_mis, exp_mis, 
        obs_syn, exp_syn, syn_z] = deserializeConcatString(gnomadConstraint, '@');
      // put data object in array to match SV
      this.gnomadConstraint = [{ pli, mis_z, exp_lof, obs_lof, 
        exp_mis, obs_mis, exp_syn, obs_syn, syn_z }];
    }
    Object.assign(this, parseNhlbi(nhlbi));
    Object.assign(this, parseConservation(conservation));
    Object.assign(this, parseFunctional(functional));
    Object.assign(this, parseGnomad(gnomad));
    if (maverick) this.maverick = parseMaverick(maverick, params.inheritance || null);
    if (gerp) this.gerp = parseGerp(gerp);
    if (spliceai) {
      this.spliceai = parseSpliceai(spliceai);
    }
    if (ccr) this.ccr = Number(ccr);
    if (gnomad3) this.gnomad3 = parseGnomad(gnomad3);
    Object.assign(this, parseKgenomes(kgenomes));
    //Object.assign(this, parseExac(exac));
    Object.assign(this, parseRegulatory(regulatory));
    Object.assign(this, parseEvolution(evolution));
    Object.assign(this, parseSplice(splice));
    this.phenoCounts = data.phenocounts ? parsePhenoCounts(data.phenocounts) : null;
    this.genesisCounts = this.phenoCounts ? sumPhenoCounts(this.phenoCounts) : 0;
    if (data.transcripts) {
      this.transcripts = data.transcripts.split('<>').map(t => {
        let [enscdna, ensprotein, ontology, canonical] = deserializeConcatString(t, '@');
        enscdna = enscdna ? decodeVepProtein(enscdna) : null;
        ensprotein = ensprotein ? decodeVepProtein(ensprotein) : null;
        let [enst, hgvsc] = enscdna ? enscdna.split(':') : [null, null];
        let [ensp, hgvsp] = ensprotein ? ensprotein.split(':p.') : [null, null];
        return {
          enscdna, enst, hgvsc, ensprotein, ensp, hgvsp,
          canonical: canonical ? true : false,  // convert from 1/0 encoding
          ontology,
          ontologyText: model.getEnumName('vep_sequence_ontology', ontology)
        };
      });
      // Lowest ontology code corresponds to most deleterious, use canonical
      // transcipt as tiebreaker
      this.worstTranscript = this.transcripts.reduce((minTran, t) =>
        !minTran || t.ontology < minTran.ontology || 
          t.ontology == minTran.ontology && t.canonical ? t : minTran);
      this.enst = this.worstTranscript.enst;
      this.hgvsc = this.worstTranscript.hgvsc;
      this.ensp = this.worstTranscript.ensp;
      this.hgvsp = this.worstTranscript.hgvsp;
      this.ontology = this.worstTranscript.consequence;
      this.ontologyText = this.worstTranscript.ontologyText;
    }
    this.afRating = afRating(this);
    this.conservRating = conservationRating(this);
    this.funcRating = functionalRating(this);
    ///this.ontology = data.vep_sequence_ontology;
    this.familyAlleles = `${this.ref}/${this.alt}`
    // Cannot declare on FamilyVariant because relies on genotypes
    this.sampleGenotypes = sampleGenotypes(this.genotypes,
      this.phases,
      parseNumberString(data.dp), parseNumberString(data.gq),
      parseNumberString(data.qual), parseNumberString(data.cnn),
      parseNumberString(data.roh), parseNumberString(data.ref_reads),
      parseNumberString(data.alt_reads), this.samples);
  }

  get maxGeno() {
    let maxGeno = { 'genotype': '', 'alleleCount': -1 }
    for (let g of this.genotypes) {
      let count = alleleCount(g, this.alt);
      if (count > maxGeno.alleleCount) { 
        maxGeno = { 'genotype': g, 'alleleCount': count };
      }
    }
    return maxGeno;
  }

}

function parseNhlbi(nhlbi) {
  let [allmafinpercent, aa_estimatedage_kyrs, 
    africanamericanallelecountmafinpercent, 
    europeanamericanallelecountmafinpercent, ea_estimatedage_kyrs
  ] = new Array(5).fill(null); ;
  if (nhlbi) [
    allmafinpercent, aa_estimatedage_kyrs, 
    africanamericanallelecountmafinpercent, 
    europeanamericanallelecountmafinpercent, ea_estimatedage_kyrs
  ] = deserializeConcatString(nhlbi, '@');
  return {allmafinpercent, aa_estimatedage_kyrs, 
    africanamericanallelecountmafinpercent, 
    europeanamericanallelecountmafinpercent, ea_estimatedage_kyrs
  };
}

function parseNumberString(s, delim = ",") {
  if (s == null || s == "NULL") return null;
  if (s == 0 || Number(s)) return [Number(s)] 
  let val = deserializeConcatString(s, delim);
  if (val.find(v => v != null) == undefined) return null;
  return val;
}

function parseRegulatory(reg) {
  let [ 
    targetscans_name, wgrna_name, tfbsconssites_name, 
    wgencoderegdnaseclusteredv3_sourcecount, wgencoderegtfbsclusteredv3_expcount,
    gwava_region_score, gwava_tss_score, gwava_unmatched_score
  ] = new Array(8).fill(null);
  if (reg) [
    targetscans_name, wgrna_name, tfbsconssites_name, 
    wgencoderegdnaseclusteredv3_sourcecount, wgencoderegtfbsclusteredv3_expcount,
    gwava_region_score, gwava_tss_score, gwava_unmatched_score
  ] = deserializeConcatString(reg, '<>');
  return {
    targetscans_name, wgrna_name, tfbsconssites_name, 
    wgencoderegdnaseclusteredv3_sourcecount, wgencoderegtfbsclusteredv3_expcount,
    gwava_region_score, gwava_tss_score, gwava_unmatched_score
  };
}

function parseSplice(splice) {
  let [ada_score, rf_score] = new Array(2).fill(null);
  if (splice) [ada_score, rf_score] = deserializeConcatString(splice, '@');
  return {ada_score, rf_score};
}

function sumPhenoCounts(phenoCounts) {
  return phenoCounts.reduce((total, p) => total + p.hets + p.homs*2, 0);
}

// // pheno and code swapped between SNV
// function parseSvPhenoCounts(phenodata) {
//   return phenodata.split('<>').map(t => {
//     let [code, pheno, hets, homs] = deserializeConcatString(t, '@');
//     return {pheno, code,  hets, homs};
//   });
// }

function parseNcbi(ncbi) {
  return ncbi.split('||').map(n => {
    let [proteinId, gene, geneId, cdd, desc, length
    ] = deserializeConcatString(n, '<>')
    return {proteinId, gene, geneId, desc, length,
      cdd: cdd ? cdd.replace('CDD:','') : null};
  });
}

// DEPRECATED
function parseExac(exac) {
  let [ 
    af, ac_adj, ac_afr, ac_amr, ac_eas, ac_fin, ac_nfe, ac_oth, ac_sas
  ] = new Array(9).fill(null);
  if (exac) [
    af, ac_adj, ac_afr, ac_amr, ac_eas, ac_fin, ac_nfe, ac_oth, ac_sas
  ] = deserializeConcatString(exac, '@');
  return {af, ac_adj, ac_afr, ac_amr, ac_eas, ac_fin, ac_nfe, ac_oth, ac_sas};
}

function parseClinvar(s) {
  let [pmid, clinsig, rcv, phenolist, cdna, clinsigCode, clinsigCodeStrict] = 
    deserializeConcatString(s, '<>');
  if (pmid) pmid = Number(pmid) ? [pmid] : deserializeConcatString(pmid, ';');
  if (rcv) rcv = Number(rcv) ? [rcv] : deserializeConcatString(rcv, ';');
  return {pmid, rcv, clinsig, phenolist, cdna, clinsigCode, clinsigCodeStrict};
}

function parseEvolution(evo) {
  let siphy_29way_logodds = null, nthumchimpcodingdiff_name = null;
  if (evo != '<>') [siphy_29way_logodds, nthumchimpcodingdiff_name] = 
    deserializeConcatString(evo, '<>');
  return {siphy_29way_logodds, nthumchimpcodingdiff_name};
}

function parseConservation(conservation) {
  let phylop100way, phylop46way, phylop46way_placental,
    phylop46way_primates, phastcons100way, phastcons46way, 
    phastcons46way_placental, phastcons46way_primates,
    multiz46way, multiz100way;
  if (conservation) [
      phylop100way, phylop46way, phylop46way_placental,
      phylop46way_primates, phastcons100way, phastcons46way, 
      phastcons46way_placental, phastcons46way_primates,
      multiz46way, multiz100way 
    ] = deserializeConcatString(conservation, '@');
  return {phylop100way, phylop46way, phylop46way_placental,
    phylop46way_primates, phastcons100way, phastcons46way, 
    phastcons46way_placental, phastcons46way_primates,
    multiz46way, multiz100way};
}

function parseFunctional(functional) {
  let lof, cadd_score, sift_pred, polyphen2_hdiv_pred, polyphen2_hvar_pred, lrt_pred,
    provean_pred, mutationtaster_pred, mutationassessor_pred, fathmm_pred, 
    metasvm_pred, metalr_pred, vest3_score;
  if (functional) [
    lof, cadd_score, sift_pred, polyphen2_hdiv_pred, polyphen2_hvar_pred, lrt_pred,
    provean_pred, mutationtaster_pred, mutationassessor_pred, fathmm_pred, 
    metasvm_pred, metalr_pred, vest3_score
  ] = deserializeConcatString(functional, '@');
  return {
    lof, cadd_score, sift_pred, polyphen2_hdiv_pred, polyphen2_hvar_pred, lrt_pred,
    provean_pred, mutationtaster_pred, mutationassessor_pred, fathmm_pred, 
    metasvm_pred, metalr_pred, vest3_score
  };
}

function parseGnomad(gnomad) {
  let gnomad_ac, gnomad_an, gnomad_af, gnomad_het, gnomad_hom, 
    gnomad_ac_afr, gnomad_ac_amr, gnomad_ac_asj, gnomad_ac_eas, 
    gnomad_ac_fin, gnomad_ac_nfe, gnomad_ac_oth,
    gnomad_an_afr, gnomad_an_amr, gnomad_an_asj, gnomad_an_eas,
    gnomad_an_fin, gnomad_an_nfe, gnomad_an_oth;
  if (gnomad) [
    gnomad_ac, gnomad_an, gnomad_af, gnomad_het, gnomad_hom, 
    gnomad_ac_afr, gnomad_ac_amr, gnomad_ac_asj, gnomad_ac_eas, 
    gnomad_ac_fin, gnomad_ac_nfe, gnomad_ac_oth,
    gnomad_an_afr, gnomad_an_amr, gnomad_an_asj, gnomad_an_eas,
    gnomad_an_fin, gnomad_an_nfe, gnomad_an_oth      
  ] = deserializeConcatString(gnomad, '@');
  return {
    gnomad_ac, gnomad_an, gnomad_af, gnomad_het, gnomad_hom, 
    gnomad_ac_afr, gnomad_ac_amr, gnomad_ac_asj, gnomad_ac_eas, 
    gnomad_ac_fin, gnomad_ac_nfe, gnomad_ac_oth,
    gnomad_an_afr, gnomad_an_amr, gnomad_an_asj, gnomad_an_eas,
    gnomad_an_fin, gnomad_an_nfe, gnomad_an_oth      
  };       
}

function parseMaverick(maverick, inheritParam) {
  let benign, dom, rec, inherit;
  [benign, dom, rec] = deserializeConcatString(maverick, '@');
  switch(inheritParam) {
    case "AD":
    case "DOM":
    case "DN":
      inherit = dom;
      break;
    case "HR":
    case "REC":
    case "CHR":
      inherit = rec;
      break;
    default:
      inherit = 1 - benign;
  }
  return {benign, dom, rec, inherit};
}

function parseGerp(gerp) {
  let neutral, rs;
  [neutral, rs] = deserializeConcatString(gerp, '@');
  return {neutral, rs};
}

function parseSpliceai(spliceai) {
  let acceptor_gain, acceptor_loss, donor_gain, donor_loss,
    acceptor_gain_dist, acceptor_loss_dist, donor_gain_dist, donor_loss_dist;
  [
    acceptor_gain, acceptor_loss, donor_gain, donor_loss,
    acceptor_gain_dist, acceptor_loss_dist, donor_gain_dist, donor_loss_dist
  ] = deserializeConcatString(spliceai, '@');
  const max = Math.max( acceptor_gain, acceptor_loss, donor_gain, donor_loss);
  return {
    acceptor_gain, acceptor_loss, donor_gain, donor_loss,
    acceptor_gain_dist, acceptor_loss_dist, donor_gain_dist, donor_loss_dist,
    max
  };
}

function parseKgenomes(kgenomes) {
  let kgenomes_af, kgenomes_af_amr, kgenomes_af_afr, kgenomes_af_eur,
    kgenomes_af_eas, kgenomes_af_sas; 
  if (kgenomes) [
    kgenomes_af, kgenomes_af_amr, kgenomes_af_afr, kgenomes_af_eur,
    kgenomes_af_eas, kgenomes_af_sas
  ] = deserializeConcatString(kgenomes, '@');
  return {kgenomes_af, kgenomes_af_amr, kgenomes_af_afr, kgenomes_af_eur,
    kgenomes_af_eas, kgenomes_af_sas
  };
}

function functionalRating(snv) {
  let rating = 0;
  if (snv.sift_pred === 0) rating++;
  if (snv.polyphen2_hdiv_pred === 0 || snv.polyphen2_hdiv_pred === 1) rating++;
  if (snv.lrt_pred === 0) rating++;
  if (snv.mutationtaster_pred === 0 || snv.mutationtaster_pred === 1) rating++;
  if (snv.mutationassessor_pred === 0 || snv.mutationassessor_pred === 1) rating++;
  if (snv.fathmm_pred === 0) rating++;
  if (snv.provean_pred === 0) rating++;
  if (snv.metasvm_pred === 0) rating++;
  if (snv.metalr_pred === 0) rating++;
  return rating/9*5;  // scale number of metrics for a 5-star rating
}

function conservationRating(snv) {
  let rating = 0;
  // NB: Null (undefined) properties always return false in comparison
  if (snv.phastcons100way > 0.6) rating++;
  if (snv.phylop100way > 1.5) rating++;
  if (snv.phastcons46way > 0.6) rating++;
  if (snv.phylop46way > 1.5) rating++;
  if (snv.multiz46way > 0.5) rating++;
  if (snv.multiz100way > 0.5) rating++;
  //if (snv.siphy_29way_logodds > 2) rating++;
  return rating/7*5;  // scale number of metrics for a 5-star rating
}

function afRating(snv) {
  if (  // variant was not detected by any study
    !snv.gnomad3 && !snv.gnomad_af
  ) return 0;
  else if (  // variant was below .02 maf in all
    (!snv.gnomad3 || snv.gnomad3.gnomad_af < .01) &&
    (!snv.gnomad_af || snv.gnomad_af < .01)
  ) return 1;
  else return 2;
}

// This is the previous rating system kept around for reference
function afRatingOld(snv) {
  if (  // variant was not detected by any study
    !snv.kgenomes_af && !snv.gnomad_af && !snv.allmafinpercent
  ) return 0;
  else if (  // variant was below .02 maf in all
    (!snv.kgenomes_af || snv.kgenomes_af < .01) && 
    (!snv.gnomad_af || snv.gnomad_af < .01) &&
    (!snv.allmafinpercent || snv.allmafinpercent < 1)
  ) return 1;
  else return 2;
}

function alleleCount(genotype, allele) { 
  let count = 0;
  const alleles = genotype.split('/');
  alleles.forEach(function(a){
    if (a == allele) count++;
  });
  return count;
}

// Translate abbreviated ensembl id by left padding zeroes and prefixing 'ENS'
// T566188.1:c.592C>T => ENST00000566188.1:c.592C>T
function decodeVepProtein(s) {
  let firstDot = s.indexOf('.');
  return 'ENS' + s.substr(0,1) +  // ENST
    s.substr(1, firstDot-1).padStart(12, '0') +  // 00000566188
    s.substr(firstDot)  // .1:c.592C>T
}

// Declared as a stand-alone function because using as a getter on the class
// would cause an intinite digest loop
function sampleGenotypes(
  genotypes, phases, dp, gq, qual, cnn, roh, refReads, altReads, samples
) {
  let a = [];
  for (let i = 0; i < genotypes.length; i++) {
    a.push({
      genotype: genotypes[i],
      phase: phases ? phases[i] : null,
      dp: dp ? dp[i] : null,
      gq: gq ? gq[i] : null,
      qual: qual ? qual[i] : null,
      cnn: cnn ? cnn[i] : null,
      roh: roh ? roh[i] : null,
      refReads: refReads ? refReads[i] : null,
      altReads: altReads ? altReads[i] : null,
      sample: samples[i]
    });
  }
  return a;
}

export default FamilySnv;
