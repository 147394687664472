import tgppatient from './oldPatient';
import tgpgroup from './group';
import Papa from "papaparse";
import geneRefs from './geneRefs';
import geneAliasesModule from './geneAliases';
import {Lab, LabMember, Project, ProjectMember} from './baseEntities/groups';
import { User } from "./baseEntities/user";
import {Diagnosis, DiagnosisGroup, Hpo} from './diagnosisEntities';
import sampleUiGridColumnDefs from './sampleUiGridColumnDef';
import Sample from './baseEntities/sample';
import Patient from './baseEntities/patient';
import Family from './baseEntities/family';
import tgpParse from './parseResults';
import {queryFilters} from './queryFilters';
import PresetStore from './presetStore';
import tgpStore from './tgpStore';
import VariantStatusModule from './variantStatus';
const {SolvedStatus, VariantStatus} = VariantStatusModule;
import SampleCrossfilter from './sampleCrossfilter';
import SampleFilters from './sampleFilters';
import SampleSet from './sampleSet';
import model from './model';
import util from './util';
import enums from '../../../data/enums.json';
import config from '../../../data/tgpConfig.json';
import annotations from '../../../data/annotations.json';
import {deserializeUploadList, deserializeSampleStatus} from './baseEntities/upload';

var userData = null;
const variantStatusData = [], forceSolvedData = [];
let activeUserId = null;
let familyWgsSamples = {};

function init(data: any) {
  userData = data;

  initDiagnosisGroups();
  initQueryFilterSettings();

  pathogenicStatusClassifier(userData.variants);

  // Run prior to main loop so that variant statuses can be added when creating
  // families
  variantStatusHandler(variantStatusData);

  for (let l of data.labs) {
    tgpStore.addLab(l.id, l.name, l.description, l.affiliation, l.country);
  }

  for (let p of data.projects) {
    tgpStore.addProject(p.id, p.name, p.description);
  }

  for (let pu of data.projectUsers) {
    tgpStore.addProjectUser(
      pu.groupId, pu.userId, pu.firstName, pu.lastName, pu.username, pu.role,
      pu.membershipDate
    )
  }
     
  for (let lu of data.labUsers) {
    tgpStore.addLabUser(
      lu.groupId, lu.userId, lu.firstName, lu.lastName, lu.username, lu.role,
      lu.membershipDate
    )
  }

  tgpStore.setLoginUser(data.userId);

  for (let p of data.patients) {
    // Skip patients with no active samples
    if (!p.samples) continue;
    const lab = tgpStore.labLookup[p.labId];
    const projs = 'projects' in p ?
      p.projects.map((proj: string) => tgpStore.findProjectById(proj)) : [];
    const samps = []
    // optional patient attributes: diagnoses & hpo
    const patDiags = initPatientDiagnoses(p);
    const patHpos = initPatientHpos(p);

    if (!tgpStore.findFamilyById(p.familyId)) {
      const varFlags = tgpStore.findFamilyVariants(p.familyId) ?
        tgpStore.findFamilyVariants(p.familyId) : {};
      const newFam = new Family(
        p.familyId, p.familyExternalId, p.familyMatchId, [],
        varFlags, p.hasPedigree);
      tgpStore.addFamily(newFam);
    }

    const fam = tgpStore.findFamilyById(p.familyId);
    for (let s of p.samples) {
      const samp = new Sample(s.sampleGenomicId, s.sampleMatchNumber, s.createDate,
        s.externalSampleId, s.experimentType, s.isPrimarySample, s.hasSnv,
        s.hasSv, s.hasCnn, s.hasRoh, s.isShared, s.sampleId, s.fileType, s.patient,
        s.alignmentRate, s.avgDepth, s.avgGq, s.avgQual, s.duplicationRate,
        s.exonEnrichment, s.genderMismatch, s.hetHomRatio,
        s.inconsistentReadNumber, s.snpCount, s.indelCount, s.read1Quality,
        s.read2Quality, s.readNumber, s.readPairingRate, s.readQualityDiff,
        s.referenceCoverage,
        s.bamStatus);
      samps.push(samp);
    }
    const pat = new Patient(p.patientId, fam, lab, 
      p.individualId, p.genomicStoreId, p.patientMatchNumber, p.affection, p.sex, p.ageOfOnset, p.superPopulation,
      p.consanguinity,
      p.modeOfInheritance, p.hasNotes, p.notes || null, samps, projs, patDiags, patHpos);
    for (let s of samps) {
      s.patient = pat;
      tgpStore.addSample(s);
    }
    fam.patients.push(pat);
    tgpStore.addPatient(pat);
  }
  // End main loop
  
  // Call these after main loop when families initialized in tgpStore
  forceSolvedHandler(forceSolvedData);
  tgpStore.assignVariantStatusFamilies();
  
  activeUserId = userData.userId;
  // Recode the dates
  const groupUsers = tgpgroup.jointGroupUserData(userData);
  for (let u of groupUsers) {
    u.membershipDate = new Date(u.membershipDate);
  };

  model.sampleCrossfilter = new SampleCrossfilter(tgpStore.samples);
  model.sampleFilters = new SampleFilters(model.sampleCrossfilter);
}

function initDiagnosisGroups() {
  const cmtDiagnosisGroup = new DiagnosisGroup(
    'cmt', 'Charcot-Marie-Tooth', [166, 65753, 53739]);
  const hspDiagnosisGroup = new DiagnosisGroup(
    'hsp', 'Hereditary spastic paraplegia', [102012, 102013]);
  const ataxiaDiagnosisGroup = new DiagnosisGroup(
    'ataxia', 'Ataxia', [183518]);
  model.diagnosisGroups.push(cmtDiagnosisGroup, hspDiagnosisGroup,
    ataxiaDiagnosisGroup);
}

function initQueryFilterSettings() {
  model.queryPresetStore = new PresetStore();
  model.samplePresetStore = new PresetStore();
}

function initPatientDiagnoses(patientData: any) {
  if (!('diagnoses' in patientData)) return null;
  const diags = [];
  for (let d of patientData.diagnoses) {
    let dg = tgpStore.createOrRetrieveDiagnosis(d);
    diags.push(dg);
  }
  return diags;
}

function initPrimaryDiagnosisLookup(data: any) {
  const parseConfig = {
    header: true, dynamicTyping: true, skipEmptyLines: true
  }
  const parsed = Papa.parse(data, parseConfig);
  for (let {SrcIdText, PrimaryDiagnosisCode, PrimaryDiagnosisName} of parsed.data) {
    model.primaryDiagnosisLookup[SrcIdText] = {
      name: PrimaryDiagnosisName, code: PrimaryDiagnosisCode
    };
  }  
}

function initPatientHpos(patientData: any) {
  if (!('hpo' in patientData)) return null;
  const patHpos = [];
  for (let h of patientData.hpo) {
    let hpo = tgpStore.createOrRetrieveHpo(h)
    patHpos.push(hpo);
  }
  return patHpos;
}

function getAnnotationDetails(type) {
  if (!annotations[type]) return null;
  return annotations[type];
}

function pathogenicStatusClassifier(pathogenicStatuses) {
  for (let s of pathogenicStatuses) {
    if ('variantId' in s) variantStatusData.push(s);
    else forceSolvedData.push(s);
  }
}

function variantStatusHandler(variantStatusData) {
  for (let v of variantStatusData)
    tgpStore.addVariantStatus(new VariantStatus(v));
}

function forceSolvedHandler(forceSolvedData) {
  for (let v of forceSolvedData) {
    const fam = tgpStore.findFamilyById(v.familyId);
    v['family'] = fam;
    fam.addForceSolvedGene(new SolvedStatus(v));
  }
}

function familyWgsSamplesLookup(familyId) {
  return familyWgsSamples[familyId];
}

function isReady() {
  if (userData) return true
  return false
}

// function addGroup(groupType, id, name, description, createTime, 
//     ownerId = activeUserId, data = userData) {
function addGroup(groupType, newGroup, createTime, 
    ownerId = activeUserId, data = userData) {
  let [groups, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  let ownerInfo = getUserInfo(ownerId, data);
  let owner = {
    role: 0, 
    userId: ownerId, 
    groupId: newGroup.id, 
    membershipDate: createTime,
    ...ownerInfo
  };
  // test
  groups.push(newGroup);
  groupUsers.push(owner);
}

function addGroupUsers(groupType, newGroupUsers, data = userData) {
  let [, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  groupUsers.push(...newGroupUsers);
}

function addPatientToProject(patientId, projectId, patients = userData.patients) {
  tgppatient.addPatientToProject(patientId, projectId, patients);
}

function removePatientFromProject(patientId, projectId, patients = userData.patients) {
  tgppatient.removePatientFromProject(patientId, projectId, patients);
}

function removeGroup(groupType, deleteGroupId, data = userData) {
  let [groups, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  tgpgroup.removeGroup(groups, groupUsers, deleteGroupId);
}

function removeGroupUsers(groupType, deleteGroupUsers, data = userData) {
  let [, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  tgpgroup.removeGroupUsers(groupUsers, deleteGroupUsers);
}

function getUserInfo(userId, data = userData) {
  let user = tgpgroup.jointGroupUserData(data).find(u => u.userId == userId);
  return {
    firstName: user.firstName, 
    lastName: user.lastName, 
    username: user.username
  };
}

function userHasFamilyLabAdminAccess(userId, familyId) {
  const pats = getFamilyPatients(familyId);
  return pats.some(p => userHasPatientLabAdminAccess(userId, p.patientId));
}

function userHasPatientLabAdminAccess(userId, patientId, data = userData) {
  const labMemberRoleCode = 2;
  const patientLabId = getPatient(patientId).labId;
  const adminLabs = tgpgroup.adminUserGroups(
    userId, labMemberRoleCode, data.labUsers);
  return adminLabs.includes(patientLabId);
}

function getUserData() {return userData;}

function getPatient(patientId, data = userData) {
  return data.patients.find(p => p.patientId == patientId);
}

function getFamilyPatients(familyId, data = userData) {
  return data.patients.filter(p => p.familyId == familyId);
}

function initPopulationQcStats(statsRawString) {
  const parseConfig = {
    header: true, dynamicTyping: true, skipEmptyLines: true, delimiter: "\t"
  }
  const parsed = Papa.parse(statsRawString, parseConfig);
  model.initPopulationQcStats(parsed.data);
}

function groupDash(groupType, data = userData) {
  let [groups, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  if (groupType == "lab") groups = userMemberLabs()
  return tgpgroup.groupDash(groupType, groups, groupUsers, data.patients,
    data.userId);
}

function newGroupMembers(groupType, data = userData) {
  const [groups, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  return tgpgroup.newGroupMembers(groups, groupUsers, data.userId);
}

function newGroupMembership(groupType, data = userData) {
  const [groups, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  return tgpgroup.newGroupMembership(groups, groupUsers, data.userId);
}

function groupMembers(groupType, groupId, data = userData) {
  const [groups, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  return tgpgroup.groupMembers(groupUsers, groupId);
}

function groupSummary(groupType, groupId, data = userData) {
  const [groups, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  return tgpgroup.groupSummary(groups, groupUsers, data.userId)
    .find(s => s.id == groupId);
}

function setGroupUserRole(groupType, groupUserMod, data = userData) {
  let [, groupUsers] = tgpgroup.groupTypeData(data, groupType);
  tgpgroup.setGroupUserRole(groupUserMod, groupUsers);
}

function userMemberLabs(userId = activeUserId, {labs, labUsers} = userData) {
  let memberLabIds = labUsers.filter(lu => lu.userId == userId)
    .map(lu => lu.groupId);
  return labs.filter(l => memberLabIds.includes(l.id));
}

function patientHpoTermSet(patients = userData.patients) {
  let hpoSet =  patients.reduce((hpos, patient) => {
    if (patient.hasOwnProperty("hpo")) {
      for (var hpo of patient.hpo) {
        hpoSet.add(hpo.name)
      }
    }
    return hpos;
  }, new Set());
  return Array.from(hpoSet);
}

function patientSearchTerms(patients = userData.patients) {
  return tgppatient.searchTerms(patients);
}

function patientGridView(patients = userData.patients) {
  return tgppatient.gridView(patients);
}


function labMembers(labId) {
  return userData.labUsers.filter(lu => lu["labId"] == labId)
}

function newLabMembers() {
  return userData.labUsers
    .filter(lu => lu.userId != activeUserId)  // all users except self
    .sort((a, b) => b.membershipDate - a.membershipDate)  // desc order
    .slice(0, 4);  // return top 5
}

function newLabMembership() {
  return userData.labUsers
    .filter(lu => lu.userId == activeUserId)  // only self lab membership
    .sort((a, b) => b.membershipDate - a.membershipDate)  // desc order
    .slice(0, 4);  // return top 5
}


// DEPRECATED in favor of tgpgroup.mapValuesToObjects
function addCountToLabs(labs, counts, prop) {
  for (const [lab, count] of Object.entries(counts)) {
    const idx = labs.findIndex(l => l["name"] == lab);
    if (idx < 0) continue  // skip patient if user doesn't belong to lab
    labs[idx][prop] = count;
  }
  return labs;
}

// DEPRECATED in favor of addCountToLabs
function addUserCountToLabs(labs, userCounts) {
  for (const [lab, userCount] of Object.entries(userCounts)) {
    const idx = labs.findIndex(l => l["name"] == lab);
    labs[idx]["totalUsers"] = userCount;
  }
  return labs;
}

function listLabUsers(lab) {
  return userData.labUsers.filter(lu => lu.LabName == lab);
}

function labUserCounts() {
  return userData.labUsers.map(user => user["labName"]).reduce(
    (acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {}
  );
}

const deserializeQueryResults = tgpParse.deserialize;
const families = tgpStore.families;
const initGeneRefs = geneRefs.initGeneRefs;
const getGeneRefs =  geneRefs.getGeneRefs;
const getEnumName = model.getEnumName;
const getUniprotId = geneRefs.getUniprotId;
const labs = tgpStore.labs;
const labLookup = tgpStore.labLookup;
const hugoGenes = geneRefs.hugoGenes;
const patients = tgpStore.patients;
const projectLookup = tgpStore.projectLookup;
const projects = tgpStore.projects;
const knownDiseaseGenes = model.knownDiseaseGenes;
const knownDiseaseGenesLookup = model.knownDiseaseGenesLookup;
const samples = tgpStore.samples;
const geneAliases = geneAliasesModule;

export  {
  addGroup,
  addGroupUsers,
  addPatientToProject,
  annotations,
  config,
  deserializeQueryResults,
  deserializeSampleStatus,
  deserializeUploadList,
  enums,
  families,
  init,
  initGeneRefs,
  initPopulationQcStats,
  isReady,
  familyWgsSamplesLookup,
  geneAliases,
  getAnnotationDetails,
  getEnumName,
  getUserData,
  getGeneRefs,
  getUniprotId,
  groupDash,
  groupSummary,
  groupMembers,
  hugoGenes,
  initPrimaryDiagnosisLookup,
  knownDiseaseGenes,
  knownDiseaseGenesLookup,
  labLookup,
  labs,
  labMembers,
  labUserCounts,
  model,
  newGroupMembers,
  newGroupMembership,
  newLabMembers,
  newLabMembership,
  patients,
  patientGridView,
  patientSearchTerms,
  projectLookup,
  projects,
  queryFilters,
  removeGroup,
  removeGroupUsers,
  removePatientFromProject,
  samples,
  sampleUiGridColumnDefs,
  SampleSet,
  setGroupUserRole
  , tgpStore
  , userHasFamilyLabAdminAccess
  , userHasPatientLabAdminAccess
  , util
};

